import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import {
    PageHeader,
    PageSection,
    PageSubSection,
    RelatedContent,
    CrossLink,
    IndicationPageIntro,
    AnchorLinks,
    PrimaryClaim,
    SecondaryCallout,
    KMCurve,
    ComparisonTable,
    NCCNcallout,
    EndpointDisplay,
    ReferencesBlock,
    TextBlock,
} from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import HNSCC_1L_COMBO from '../../components/StudyDesign/__study-design/hnscc-1l-combo';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-048 - Clinical Trial Results for First-line Combination Therapy`,
    keywords: `keynote 048, first-line combination therapy hnscc clinical trial results`,
    description: `Health care professionals may find clinical trial results from KEYNOTE-048 in first-line combination therapy in patients with metastatic or unresectable, recurrent head and neck squamous cell carcinoma (HNSCC).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/hnscc-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/hnscc-first-line-combination-therapy/","@type":"MedicalTrial","name":"KEYNOTE-048","description":"Clinical Findings from KEYNOTE⁠-⁠048","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠048, a randomized, multicenter, open-label, active-controlled trial conducted in 882 patients with metastatic HNSCC who had not previously received systemic therapy for metastatic disease or with recurrent disease who were considered incurable by local therapies. Patients with active autoimmune disease that required systemic therapy within 2 years of treatment or a medical condition that required immunosuppression were ineligible. Randomization was stratified by tumor PD⁠-⁠L1 expression (TPS ≥50% or <50%) according to the PD⁠-⁠L1 IHC 22C3 pharmDx kit, HPV status according to p16 IHC (positive or negative), and ECOG PS (0 vs 1)."}`,
    ],
};

//from /src/util/data/indications.js
const indicationId = 9;
const jobCode = jobCodes[9].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action',
        },
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support',
        },
    },
];

const crossLinkContent = {
    sections: [
        {
            heading: 'Additional Clinical Data',
            items: [
                { text: 'HNSCC 1L Monotherapy', url: '/efficacy/hnscc-first-line-monotherapy/' },
            ],
        },
        {
            heading: 'Related Information',
            items: [
                {
                    text: 'Selected Adverse Reactions',
                    url: '/safety/adverse-reactions/hnscc-first-line-combination-therapy/',
                },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
            ]
        },
    ],
    interestLinks: {
        heading: 'You Might Be Interested In',
        items: [
            {
                eyebrow: 'Resources',
                text: 'Mechanism of Action',
                url: '/resources/mechanism-of-action/',
            },
        ],
    },
};

const anchorLinkModelData = [
    {
        label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;048',
    },
    {
        label:
            'Select NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Head and Neck Cancers',
    },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;048' },
];

const medianOsSecondaryCalloutFootnotes = [
    {
        label: 'a.',
        text:
            'Dates for data cutoff: interim analysis: 06/13/2018; final analysis: 02/25/2019<sup>1</sup>.',
    },
    {
        label: 'b.',
        text:
            'HR based on the stratified Cox proportional hazard model.',
    },
    {
        label: 'c.',
        text:
            '<i>P</i> value based on stratified log-rank test.',
    },
];

const endPointORRfootnotes = [
    {
        label: 'd.',
        text: 'Best objective response as confirmed complete response or partial response.'
    }
]

const KMCCurveKeyVsExtremeFootnotes = [
    {
        label: 'e.',
        text: 'Date for data cutoff: 02/21/2022.',
    },
];

const NCCNcalloutData = {
    title: `Pembrolizumab (KEYTRUDA) is the ONLY CATEGORY 1 PREFERRED FIRST-LINE SYSTEMIC THERAPY OPTION for Recurrent, Unresectable, or Metastatic Head and Neck Cancers Recommended by the NCCN Guidelines® as Monotherapy (CPS ≥1) or in Combination With plat/5&#8288;-&#8288;FU (regardless of PD&#8288;-&#8288;L1 expression)<sup>4</sup>`,
    bodyCopy: `
        <p>In Non-Nasopharyngeal Recurrent, Unresectable, or Metastatic Cancers (With No Surgery or RT Option)<sup>4</sup></p>
        <h5>First-Line Pembrolizumab/platinum (cisplatin or carboplatin)/5-FU:</h5>
        <ul>
            <li>CATEGORY 1 recommendation regardless of PD&#8288;-&#8288L1 expression</li>
        </ul>
        <h5>First-Line Pembrolizumab as monotherapy (for PD&#8288;-&#8288L1 positive tumors [CPS ≥1]):</h5>
        <ul>
            <li>CATEGORY 1 recommendation for CPS ≥1</li>
        </ul>
    `,
    showBadge: false,
    alt:
        '',
};

const NCCNcalloutDefinitions = `
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
    <p>Category 1 = Based upon high-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.</p>
    <p>RT = radiotherapy; PD&#8288;-&#8288L1 = programmed death ligand 1; platinum = cisplatin or carboplatin; 5-FU = 5-fluorouracil; CPS = combined positive score.</p>
    `;

const NCCNcalloutReferences = [
    {
        label: '4.',
        text: 'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Head and Neck Cancers V.4.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed May 1, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org.',
    },
];

const pageReferences = [
    {
        label: '1.',
        text:
            'Burtness B, Harrington KJ, Greil R, et al; for the KEYNOTE-048 investigators. Pembrolizumab alone or with chemotherapy versus cetuximab with chemotherapy for recurrent or metastatic squamous cell carcinoma of the head and neck (KEYNOTE-048): a randomised, open-label, phase 3 study. <i>Lancet.</i> 2019;394(10212):1915–1928.',
    },
    {
        label: '2.',
        text:
            'Burtness B, Harrington KJ, Greil R, et al; for the KEYNOTE-048 investigators. Supplementary Appendix to: Pembrolizumab alone or with chemotherapy versus cetuximab with 	chemotherapy for recurrent or metastatic squamous cell carcinoma of the head and neck (KEYNOTE-048): a randomised, open-label, phase 3 study. (<i>Lancet.</i> 2019;394(10212):1915–1928.) 	doi:10.1016S0140-6736(19)32591-7',
    },
    {
        label: '3.',
        text:
            'Data available on request from Merck Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-OHN-01846.',
    },
    {
        label: '4.',
        text: 'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Head and Neck Cancers V.4.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed May 1, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org.',
    },
];

const Page = ({ location }) => {
    return (
        <DefaultLayout
            indicationId={indicationId}
            jobCode={jobCode}
            pageMeta={pageMeta}
            location={location}
        >
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">
                        KEYTRUDA + plat/FU Demonstrated Durable Overall Survival (OS) vs EXTREME Regardless of Programmed Death Ligand 1 (PD⁠-⁠L1) Expression Across the Protocol-Specified Interim and Final Analyses
                        </PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Superior OS at the protocol-specified interim analysis (median follow-up time: 13.0 months for KEYTRUDA + plat/FU and 10.7 months for EXTREME)<sup>1,a,b,c</sup>"></PageSubSection>
                            <ComparisonTable
                                title="Overall Survival (OS): Interim Analysis"
                                data={[
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA + plat/FU (n=281)',
                                        },
                                        {
                                            legendTitle: 'EXTREME (n=278)',
                                        },
                                    ],
                                    [
                                        {
                                            hrCiP: 'HR=0.77 (95% CI, 0.63–0.93; <i>P</i>=0.0067)',
                                            centerText: true,
                                            colspan: 2,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '197/281 (70%)',
                                        },
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '223/278 (80%)',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median OS',
                                            dataPoint: '13.0 months',
                                            dataSubPoint: '(95% CI, 10.9–14.7)',
                                        },
                                        {
                                            label: 'Median OS',
                                            dataPoint: '10.7 months',
                                            dataSubPoint: '(95% CI, 9.3–11.7)',
                                        },
                                    ],
                                ]}
                            />

                            <SecondaryCallout
                                title="Median OS was 13.0 months with KEYTRUDA + plat/FU vs 10.7 months with EXTREME<sup>1,a</sup>"
                                footnotes={medianOsSecondaryCalloutFootnotes}
                                definitions="EXTREME = cetuximab + platinum + fluorouracil."
                                alt=""
                            />

                            <PageSubSection title="Durable OS at the protocol-specified final analysis (median follow-up time: 13.0 months for KEYTRUDA + plat/FU and 10.7 months for EXTREME)<sup>1,a</sup>">

                            <ComparisonTable
                                    additionalInformation={[
                                        {
                                            text: "This protocol-specified final analysis occurred after the interim analysis, which demonstrated the superiority of KEYTRUDA + plat/FU in overall survival compared to EXTREME, regardless of PD&#8288;-&#8288L1 expression. The interim analysis was the definitive study finding for overall survival with KEYTRUDA + plat/FU."
                                        },
                                    ]}
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + plat/FU (n=281)',
                                            },
                                            {
                                                legendTitle: 'EXTREME (n=278)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.72 (95% CI, 0.60&ndash;0.87)',
                                                colspan: 2,
                                                centerText: true,
                                            },
                                        ],
                                    ]}
                                />
                            </PageSubSection>

                            <PageSubSection title="Progression-Free Survival (PFS) at the protocol-specified interim analysis (median follow-up time: 13.0 months for KEYTRUDA + plat/FU and 10.7 months for EXTREME)<sup>1,a,b</sup>">
                                <ComparisonTable
                                    title="PFS: Interim Analysis"
                                    definitions="CI = confidence interval; CPS = combined positive score; HR = hazard ratio; NS = not significant."
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + plat/FU (n=281)',
                                            },
                                            {
                                                legendTitle: 'EXTREME (n=278)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.92 (95% CI, 0.77&ndash;1.10); <i>P</i>=NS',
                                                centerText: true,
                                                colspan: 2,
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '244/281 (87%)',
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '253/278 (91%)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '4.9 months',
                                                dataSubPoint: '(95% CI, 4.7–6.0)',
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '5.1 months',
                                                dataSubPoint: '(95% CI, 4.9–6.0)',
                                            },
                                        ],
                                    ]}
                                />
                            </PageSubSection>

                            <PageSubSection title="ORR and DOR: at the Protocol-Specified Interim Analysis (median follow-up time: 13.0 months for KEYTRUDA + plat/FU and 10.7 months for EXTREME)<sup>1,a</sup>">
                                <EndpointDisplay
                                    title="Objective Response Rate (ORR): KEYTRUDA + plat/FU vs EXTREME<sup>d</sup>"
                                    limitation="In 281 patients treated with KEYTRUDA + plat/FU vs 278 patients with EXTREME regimen"
                                    footnotes={endPointORRfootnotes}
                                    cards={[
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA + plat/FU',
                                            rate: '36%',
                                            ci: '95% Cl, 30.0–41.5',
                                            completeResponseRate: '6%',
                                            partialResponseRate: '30%',
                                        },

                                        {
                                            dataType: 'ORR',
                                            label: 'EXTREME',
                                            rate: '36%',
                                            ci: '95% CI, 30.7–42.3',
                                            completeResponseRate: '3%',
                                            partialResponseRate: '33%',
                                        },
                                    ]}
                                />
                                <ComparisonTable
                                    title="Duration of Response (DOR): KEYTRUDA + plat/FU vs EXTREME"
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + plat/FU (n=281)',
                                            },
                                            {
                                                legendTitle: 'EXTREME regimen (n=278)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median DOR',
                                                dataPoint: '6.7 months',
                                                dataSubPoint: '(range: 1.6+ to 30.4+ months)',
                                            },
                                            {
                                                label: 'Median DOR',
                                                dataPoint: '4.3 months',
                                                dataSubPoint: '(range: 1.2+ to 27.9+ months)',
                                            },
                                        ],
                                    ]}
                                    definitions="EXTREME = cetuximab + platinum + fluorouracil."
                                />
                            </PageSubSection>

                            <PageSubSection title="Exploratory Analysis (median follow-up time: 68.6 months)">
                                <KMCurve
                                    title="Kaplan-Meier Estimates of OS With KEYTRUDA + plat/FU vs EXTREME in the Total Population in KEYNOTE&#8288;-&#8288;048<sup>2,3,e</sup>"
                                    limitation="<b>LIMITATION:</b> Upon progression of disease, trial participants in KEYNOTE&#8288;-&#8288;048 could receive subsequent therapy based on investigator’s choice and were included in this post hoc analysis based on an extended 68.6-month median follow-up. This post hoc analysis was exploratory in nature and occurred after the protocol-specified final analysis. No formal statistical testing was planned for this analysis and, therefore, no conclusions can be drawn.<sup>3,e</sup>"
                                    hr="HR=0.72 (95% CI, 0.60–0.86)"
                                    additionalInformation={[
                                        { text: `
                                        <ul>
                                            <li>Number of OS events: 240/281 (85.4%) with KEYTRUDA + plat/FU and 262/278 (94.2%) with EXTREME.</li>
                                            <li>Median OS was 13.0 months (95% CI, 10.9–14.7) with KEYTRUDA + plat/FU and 10.7 months (95% CI, 9.3–11.7) with EXTREME.</li>
                                        </ul>`},
                                    ]}
                                    footnotes={KMCCurveKeyVsExtremeFootnotes}
                                    image={() => (
                                        <StaticImage
                                        src="../../assets/keynote-048-km-2-v4.webp"
                                        placeholder="blurred"
                                        alt="Kaplan-Meier Estimates of OS in Patients With Metastatic or Unresectable, Recurrent Head and Neck Squamous Cell Carcinoma (HNSCC) With KEYTRUDA® (pembrolizumab) + plat/FU vs EXTREME in Total Patient Population in KEYNOTE-048"
                                        />
                                    )}
                                />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                references={NCCNcalloutReferences}
                                definitions={NCCNcalloutDefinitions}
                            />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label} bgColor="cloud">
                            <HNSCC_1L_COMBO />
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>
                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
