import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const HNSCC_1L_COMBO = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            title="Study Overview"
            description={`
            <p>The efficacy of KEYTRUDA was investigated in KEYNOTE&#8288;-&#8288;048, a randomized, multicenter, open-label, active-controlled trial conducted in 882 patients with metastatic HNSCC who had not previously received systemic therapy for metastatic disease or with recurrent disease who were considered incurable by local therapies. Patients with active autoimmune disease that required systemic therapy within 2 years of treatment or a medical condition that required immunosuppression were ineligible. Randomization was stratified by tumor PD&#8288;-&#8288;L1 expression (TPS&nbsp;≥50% or <50%) according to the PD&#8288;-&#8288;L1 IHC 22C3 pharmDx kit, HPV status according to p16 IHC (positive or negative), and ECOG PS (0 vs 1). Patients were randomized 1:1:1 to one of the following treatment arms:</p>

            <ul>
                <li>KEYTRUDA 200 mg intravenously every 3 weeks</li>
                <li>KEYTRUDA 200 mg intravenously every 3 weeks, carboplatin AUC 5 mg/mL/min intravenously every 3 weeks or cisplatin 100&nbsp;mg/m<sup>2</sup> intravenously every 3 weeks, and FU 1000 mg/m<sup>2</sup>/day as a continuous intravenous infusion over 96 hours every 3 weeks (maximum of 6 cycles of platinum and FU)</li>
                <li>EXTREME (cetuximab + platinum + fluorouracil): Cetuximab 400&nbsp;mg/m<sup>2</sup> intravenously as the initial dose then 250&nbsp;mg/m<sup>2</sup> intravenously once weekly, carboplatin AUC 5 mg/mL/min intravenously every 3 weeks or cisplatin 100&nbsp;mg/m<sup>2</sup> intravenously every 3 weeks, and FU 1000&nbsp;mg/m<sup>2</sup>/day as a continuous intravenous infusion over 96 hours every 3 weeks (maximum of 6 cycles of platinum and FU)</li>
            </ul>
            
            <p>Treatment with KEYTRUDA continued until RECIST v1.1-defined progression of disease as determined by the investigator, unacceptable toxicity, or a maximum of 24 months. Administration of KEYTRUDA was permitted beyond RECIST-defined disease progression if the patient was clinically stable and considered to be deriving clinical benefit by the investigator. Assessment of tumor status was performed at Week 9 and then every 6 weeks for the first year, followed by every 9 weeks through 24 months. A retrospective re-classification of patients’ tumor PD&#8288;-&#8288;L1 status according to CPS using the PD&#8288;-&#8288;L1 IHC 22C3 pharmDx kit was conducted using the tumor specimens used for randomization.</p> 

            <p>The main efficacy outcome measures were OS and PFS as assessed by BICR according to RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ) sequentially tested in the subgroup of patients with CPS ≥20, the subgroup of patients with CPS ≥1, and the overall population.</p>

            <p>The study population characteristics were: median age of 61 years (range: 20 to 94), 36% age 65 or older; 83% male; 73% White, 20% Asian and 2.4% Black; 61% had ECOG PS of 1; and 79% were former/current smokers. Twenty-two percent of patients’ tumors were HPV-positive, 23% had PD&#8288;-&#8288;L1 TPS&nbsp;≥50%, and 95% had Stage IV disease (Stage IVA 19%, Stage IVB 6%, and Stage IVC 70%). Eighty-five percent of patients’ tumors had PD&#8288;-&#8288;L1 expression of CPS ≥1 and 43% had CPS ≥20.</p>
        `
        }
        definitions={`
                <p>AUC = area under the curve; BICR = blinded independent central review; ECOG PS = Eastern Cooperative Oncology Group performance status;
                HPV =  human papillomavirus; IHC = Immunohistochemistry; plat/FU = platinum + fluorouracil; 
                RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; TPS = tumor proportion score.</p>
            `} 
        />
    </ComponentWrapper>
);

export default HNSCC_1L_COMBO;
